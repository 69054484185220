import { FC } from 'react';

import { ProductsList } from 'components/ProductsList';
import { Body } from 'components/Body';

import { IShowcase } from 'types/IShowcase';

import { SectionWrapper } from '../styles';

import { SectionTitle } from '../SectionTitle';

type TSimpleListProducts = {
  showcase: IShowcase;
};

const SimpleListProducts: FC<TSimpleListProducts> = ({ showcase }) => {
  return (
    <Body>
      <SectionWrapper>
        <SectionTitle title={showcase.title} href={showcase.url} />

        <ProductsList products={showcase.products} />
      </SectionWrapper>
    </Body>
  );
};

export default SimpleListProducts;
