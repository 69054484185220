import { useCallback, useRef } from 'react';

import { useRouter } from 'next/router';

import { IProductsList } from '@pra-vendas-themes/interfaces/IProductsList';

import { useToast } from 'hooks/useToast';
import { useSiteSettings } from 'hooks/useSiteSettings';
import { useComponentsTheme } from 'hooks/useComponentsTheme';
import { useBag } from 'hooks/useBag';

import { WithChildren } from 'types/WithChildren';
import { IProduct } from 'types/IProduct';

export const ProductsList: WithChildren<IProductsList> = ({
  children,
  ...props
}) => {
  const { ProductsList: ProductsListTheme } = useComponentsTheme();
  const { storeSettings } = useSiteSettings();
  const { openToast } = useToast();
  const observer = useRef<IntersectionObserver | null>(null);

  const router = useRouter();
  const { addProductInCart, cart } = useBag();

  const handleClickProduct = (product: IProduct): void => {
    router.push(`/produtos/${product.tag}`);
  };

  const handleClickAddCart = (product: IProduct, quantity?: number): void => {
    try {
      addProductInCart(product, quantity);
    } catch (e) {
      openToast('Ops, houve um erro ao adicionar o produto no carrinho.');
    }
  };

  const quantityInBag = useCallback(
    (product) => {
      const productInCart = cart?.items.find(
        (item) => item.product?._id === product._id
      );

      return productInCart?.quantity || 0;
    },
    [cart]
  );

  const lastProductElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && props.hasMore) {
          props.setPage((prevPage: number) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [props.hasMore, props.setPage]
  );

  return (
    <ProductsListTheme
      {...props}
      onClickProduct={handleClickProduct}
      onClickAddCart={handleClickAddCart}
      quantityInBag={quantityInBag}
      informQuantityAtProductCard={
        storeSettings?.theme?.informQuantityAtProductCard
      }
      lastProductRef={lastProductElementRef}
    >
      {children}
    </ProductsListTheme>
  );
};
